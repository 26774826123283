@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Kruti Dev";
  src: url("./assets/fonts/Kruti\ Dev\ 010\ Regular.ttf");
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.wavesurfer-region:before {
  content: attr(data-region-label);
}
audio::-webkit-media-controls-play-button,
audio::-webkit-media-controls-panel {
  background-color: #fff;
  color: #000;
}

@keyframes borderAnimation {
  0% {
    width: 100%;
  }
  100% {
    width: 0;
  }
}

.animate-border {
  animation: borderAnimation 5s linear;
}

@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

/* Container with a custom scrollbar */
.notifications-list {
  overflow-y: auto;
  max-height: 400px;
  scrollbar-width: none; 
}



